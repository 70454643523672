import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here

//apply base url for axios
export const API_URL = "http://ssiservice.sparklinematrix.com/api/v1/"

const axiosApi = axios.create({
  baseURL: API_URL,
})


axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      console.log("worksS"),
        axios.post('http://localhost:5000/api/v1/refresh/', {
          refreshToken: localStorage.getItem('refresh_token')
        })
          .then(response => {
            const { access_token, refresh_token } = response.data;
            // update access token in local storage
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            // resend original request with new access token
            return axios(originalRequest);
          })
          .catch(error => {
            // handle error
          });
    } else {
      // handle error
    }
  }
  // Promise.reject(error)
)

export async function get(url, config = {}) {
  const token = JSON.parse(localStorage.getItem("authUser"))
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  const token = JSON.parse(localStorage.getItem("authUser"))
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  const token = JSON.parse(localStorage.getItem("authUser"))
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
  return axiosApi
    .put(url, { ...data }, { ...config })
    // .then(response => response.data)
}

export async function del(url, config = {}) {
  const token = JSON.parse(localStorage.getItem("authUser"))
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
