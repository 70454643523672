import { del, get } from "helpers/api_helper";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, CardTitle, Table, Button } from "reactstrap"
import '../../assets/scss/custom/components/cutom-components/users.scss'
// import ConfirmBox from "./confirmbox";
import AddAbout from "./AddAbout";
import ConfirmBox from "utils/confirmbox";

const About = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeletetedId] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editId, setEditId] = useState(null);


  const deleteData = () => {
    let promise = del(`/about/${deleteId}`);
    try {
      promise.then((response) => {
        const { data, message, status } = response;
        // console.log(response);
        // if (status) {
        // console.log(status);
          setOpen(false);
          fetchData();
        // }
      }).catch((error) => {
        console.log(error);
      })
    } catch (error) {
      console.log(error);
    }
  }

  const openConfirmBox = (id) => {
    setDeletetedId(id);
    setOpen(true);
  }

  const closeConfirmBox = (opt) => {
    if (opt === 'cancel') {
      setOpen(false);
    } else {
      deleteData();
    }
  }

  const addModalOpen = () => {
    setOpenAddModal(true);
  }

  const addModalClose = () => {
    setOpenAddModal(false);
    setEditId(null);
  }

  const fetchData = () => {
    let promise = get('/about/');
    try {
      promise.then((response) => {
        const { data, status } = response;
        if (status) {
          console.log("data", data);
          setData(data);
        }
      }).catch((error) => {
        console.log(error);
      })
    } catch (error) {
      console.log(error);
    }
  }

  const editData = (id) => {
    console.log(id);
    setEditId(id);
    setOpenAddModal(true);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Abouts</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={6}>
                <h6 className="page-title">Abouts</h6>
              </Col>
              <Col xs={6} style={{ textAlign: 'end' }}><Button onClick={() => { addModalOpen() }}>Add</Button></Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Description 2</th>
                            <th>Image</th>
                            <th colSpan={2}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.abouts && data.abouts.map((data, index) => {
                            return <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{data.title}</td>
                              <td>{data.description}</td>
                              <td>{data.description2}</td>
                              <td>{data.image}{data.phone_number}</td>
                              <td><i className="ti-pencil" onClick={() => { editData(data.id) }} /></td>
                              <td><i className="ti-trash" onClick={() => { openConfirmBox(data.id) }} /></td>
                            </tr>
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <ConfirmBox open={open} closeConfirmBox={(opt) => { closeConfirmBox(opt) }} />
      <AddAbout open={openAddModal} closeAddBox={() => { addModalClose() }} fetchData={() => { fetchData() }} editId={editId} />
    </React.Fragment>
  )
}

export default About