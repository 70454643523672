import Typed from "typed.js";
import React, { useEffect } from "react";

export function TypedText({data}) {
    useEffect(() => {
      const typed = new Typed(".text", {
        strings: data,
        typeSpeed: 70,
        backSpeed: 20,
        backDelay: 1300,
        showCursor: true,
        loop: true,
      });
      return () => {
        typed.destroy();
      };
    }, []);
    return <section className="hero">
    <div className="hero-text">
      <p className="text"></p>
    </div>
  </section>;
  }