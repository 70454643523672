import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ConfirmBox({open, closeConfirmBox}) {
    return (
        <Modal isOpen={open} centered>
            <ModalHeader >Confirm</ModalHeader>
            <ModalBody>
                Are You Sure Want to Delete ?
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {closeConfirmBox('cancel')}}>
                    Cancel
                </Button>{' '}
                <Button color="danger" onClick={() => {closeConfirmBox('confirm')}}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    )
}
