import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { API_URL, get, post, put } from "helpers/api_helper";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
// import FormData from 'form-data';


export default function addSlider({ open, closeAddBox, fetchSliders, editId }) {
  const [error, setError] = useState(null);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    image: Yup.mixed()
  });

  const userDetail = () => {
    if (editId) {
      let promise = get(`/sliders/${editId}`);
      try {
        promise.then((response) => {
          const { data, status, message } = response;
          formik.setFieldValue('title', data.title);
          formik.setFieldValue('description', data.description);
        }).catch((error) => {
          console.log(error);
        })
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    userDetail();
  }, [editId])

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      image: null
    },
    validationSchema,
    onSubmit: (values) => {
      if (editId) {
        editSlider(values);
      } else {
        addSlider(values);
      }
    },
  })

  const handleClose = (opt) => {
    setError(null);
    formik.resetForm();
    closeAddBox(opt);
    closeAddBox();
  }

  const editSlider = async (values) => {
    setError(null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('image', values.image);
    // try {
    //   put(`/sliders/${editId}`, formData).then((response) => {
    //     const { data, status, message } = response;
    //     if (!status) {
    //       setError(message);
    //     } else {
    //       fetchSliders();
    //       handleClose('confirm');
    //     }
    //   }).catch((error) => {
    //     console.log(error);
    //   })
    // } catch (error) {
    //   console.log(error);
    // }
  
    try {
      const response = await fetch(`${API_URL}sliders/${editId}`, {
        method: 'PUT',
        body: formData
      });
      const res = await response.json();
      const { data, status, message } = res;
        if (!status) {
          setError(message);
        } else {
          fetchSliders();
          handleClose('confirm');
        }
    } catch (error) {
      console.error(error);
    }
  };

  const addSlider = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('image', values.image);
    try {
      const response = await fetch(`${API_URL}sliders/`, {
        method: 'POST',
        body: formData
      });
      const res = await response.json();
      const { data, status, message } = res;
        if (!status) {
          setError(message);
        } else {
          fetchSliders();
          handleClose('confirm');
        }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Modal isOpen={open} centered>
      <form>
        <ModalHeader >Slider</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="exampleEmail">Title</Label>
                  <Input type="text" name="title" placeholder="title" onChange={formik.handleChange} value={formik.values.title} />
                  {formik.errors.title && formik.touched.title ? <p style={{ color: 'red' }}>{formik.errors.title}</p> : null}
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col sm={12}>
                <FormGroup>
                  <Label for="exampleText" >Description</Label>
                  <Input type="textarea" name="description" placeholder="description" onChange={formik.handleChange} value={formik.values.description} />
                  {formik.errors.description && formik.touched.description ? <p style={{ color: 'red' }}>{formik.errors.description}</p> : null}
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col sm={12}>
                <FormGroup>
                  <Label for="exampleText" >Image</Label>
                  <Input type="file" name="image" placeholder="image" onChange={(event) => {
                    formik.setFieldValue('image', event.currentTarget.files[0]);
                  }} />
                  {formik.errors.image && formik.touched.image ? <p style={{ color: 'red' }}>{formik.errors.image}</p> : null}
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { handleClose('cancel') }} >
            Cancel
          </Button>{' '}
          <Button color="primary"
            onClick={() => { formik.handleSubmit() }}
          >
            {editId ? 'Update slider' : 'Add slider'}
          </Button>
        </ModalFooter>
      </form>
      {error ? <p style={{ alignSelf: 'center', color: 'red' }}>{error}</p> : null}
    </Modal>
  )
}
