import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Slider from "pages/Slider"
import About from "pages/about"
import Timetable from "pages/timetable"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/", component: Dashboard },
  { path: "/slider", component: Slider },
  { path: "/about", component: About },
  { path: "/timetable", component: Timetable },

]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

const userRoutesNonLayout = [
  // { path: "/billing", component: Billing },

]

export { userRoutes, authRoutes, userRoutesNonLayout }
