import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import LoginImg from "../../assets/images/login.gif"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png";
import { TypedText } from 'components/CustomComponents/typed';

const Login = props => {
  console.log("props", props);
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | SparkLine Matrix ERP System</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="welcome-page">
        <div className="corner"></div>
        <div className="corner"></div>
        <div className="corner"></div>
        <div className="corner"></div>
        <div className="content">
          <p data-shadow="WELCOME"><span>W</span><span>E</span><span>L</span><span>C</span><span>O</span><span>M</span><span>E</span></p>
        </div>
        <div className="right-vert-line"></div>
        <div className="left-vert-line"></div>
        <div className="continue">
          <TypedText data={["SPARKLINE MATRIX", "MATRIX ERP"]} />
        </div>
      </div>
      <div className="containerLogin">
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={4}>
                <Card className="overflow-hidden">
                  <CardBody className="p-4">
                    <div className="p-3">
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {props.error && typeof props.error === "string" ? (
                          <Alert color="danger">{props.error}</Alert>
                        ) : null}
                        <div className="mb-3">
                          <AvField
                            name="username"
                            label="Username"
                            className="form-control"
                            placeholder="Enter Username"
                            type="text"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>
                        <Row className="mb-3">
                          <Col sm={6}>
                            <div className="form-check">
                              <input type="checkbox" className="form-check-input" id="customControlInline" />
                              <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                            </div>
                          </Col>
                          <Col sm={12} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light login-btn"
                              type="submit"
                            >
                              Log In
                            </button>
                          </Col>
                        </Row>
                        <Row className="mt-2 mb-0 row">
                          <div className="col-12 mt-4">
                            <Link to="/forgot-password">
                              <i className="mdi mdi-lock"></i> Forgot your
                              password?
                            </Link>
                          </div>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  history: PropTypes.object,
  loginUser: PropTypes.func,
  loading: PropTypes.bool
}