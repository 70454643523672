import { del, get } from "helpers/api_helper";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Col, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, CardTitle, Table, Button } from "reactstrap"
import '../../assets/scss/custom/components/cutom-components/users.scss'
// import AddAbout from "./AddAbout";
import ConfirmBox from "utils/confirmbox";
import { Input } from "reactstrap";

const Timetable = () => {
    const [data, setData] = useState([]);
    const [classes, setClasses] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeletetedId] = useState();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editId, setEditId] = useState(null);
    const [selectedClass, setClass] = useState("first")


    const deleteData = () => {
        let promise = del(`/about/${deleteId}`);
        try {
            promise.then((response) => {
                const { data, message, status } = response;
                // console.log(response);
                // if (status) {
                // console.log(status);
                setOpen(false);
                fetchData();
                // }
            }).catch((error) => {
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const openConfirmBox = (id) => {
        setDeletetedId(id);
        setOpen(true);
    }

    const closeConfirmBox = (opt) => {
        if (opt === 'cancel') {
            setOpen(false);
        } else {
            deleteData();
        }
    }

    const addModalOpen = () => {
        setOpenAddModal(true);
    }

    const addModalClose = () => {
        setOpenAddModal(false);
        setEditId(null);
    }

    const fetchData = () => {
        let promise = get(`/timetable?class=${selectedClass}`);
        try {
            promise.then((response) => {
                const { data, status } = response;
                if (status) {
                    const sortedTimetable = data.sort((a, b) => {
                        const days = ['monday', 'tuesday', 'wendensday', 'thursday', 'friday'];
                        const dayIndexA = days.indexOf(a.day.toLowerCase());
                        const dayIndexB = days.indexOf(b.day.toLowerCase());
                        return dayIndexA - dayIndexB;
                    });
                    setData(sortedTimetable);
                } else {
                    setData([]);
                }
            }).catch((error) => {
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const fetchAllTimeTable = () => {
        let promise = get(`/timetable`);
        try {
            promise.then((response) => {
                const { data, status } = response;
                if (status) {
                    const days = data.reduce((acc, curr) => {
                        if (!acc.includes(curr.class)) {
                          acc.push(curr.class);
                        }
                        return acc;
                      }, []);
                    setClasses(days);
                    console.log("days", days);
                } else {
                    setTimeTable([]);
                }
            }).catch((error) => {
                console.log(error);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const editData = (id) => {
        console.log(id);
        setEditId(id);
        setOpenAddModal(true);
    }

    useEffect(() => {
        fetchData();
    }, [selectedClass])

    useEffect(() => {
        fetchAllTimeTable();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Timetables</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={9}>
                                <h6 className="page-title">Timetables</h6>
                            </Col>
                            <Col xs={3} style={{ textAlign: 'end' }}>
                                <Row>
                                    <Col xs={6}>
                                        <Input type='select' onChange={(e) => { setClass(e.target.value) }}>
                                            {classes && classes.map((item, index) => {
                                                return <option key={index} vlaue={item} >{item}</option>
                                            })}
                                        </Input>
                                    </Col>
                                    <Col xs={6}>
                                        <Button onClick={() => { addModalOpen() }}>Add</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        {/* <th>#</th> */}
                                                        <th>Day</th>
                                                        <th>Hour 1</th>
                                                        <th>Hour 2</th>
                                                        <th>Hour 3</th>
                                                        <th>Hour 4</th>
                                                        <th>Hour 5</th>
                                                        <th>Hour 6</th>
                                                        <th>Hour 7</th>
                                                        <th colSpan={2}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.length !== 0 ? data.map((data, index) => {
                                                        return <tr key={index}>
                                                            {/* <th scope="row">{index + 1}</th> */}
                                                            <td><b>{data.day}</b></td>
                                                            <td>{data.hour1}</td>
                                                            <td>{data.hour2}</td>
                                                            <td>{data.hour3}</td>
                                                            <td>{data.hour4}</td>
                                                            <td>{data.hour5}</td>
                                                            <td>{data.hour6}</td>
                                                            <td>{data.hour7}</td>
                                                            <td><i className="ti-pencil" onClick={() => { editData(data.id) }} /></td>
                                                            <td><i className="ti-trash" onClick={() => { openConfirmBox(data.id) }} /></td>
                                                        </tr>
                                                    }) :
                                                        <tr><td colSpan={10}><div><b>No Data Found</b></div></td></tr>}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <ConfirmBox open={open} closeConfirmBox={(opt) => { closeConfirmBox(opt) }} />
            {/* <AddAbout open={openAddModal} closeAddBox={() => { addModalClose() }} fetchData={() => { fetchData() }} editId={editId} /> */}
        </React.Fragment>
    )
}

export default Timetable;